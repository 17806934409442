import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="components--dialog"
export default class extends Controller {
  static targets = ['button', 'dialog']
  static values = {
    clickOutsideToClose: { type: Boolean, default: false }
  }

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.dialogTarget.addEventListener('click', this.handleClickOutside)
  }

  disconnect() {
    this.dialogTarget.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.clickOutsideToCloseValue && event.target === this.dialogTarget) {
      this.closeDialog()
    }
  }

  openDialog(event) {
    event.preventDefault()
    this.dialogTarget.showModal()
  }

  cancel(event) {
    event.preventDefault()
    this.closeDialog()
  }

  closeDialog() {
    this.dialogTarget.close()
  }
}
