import { Controller } from '@hotwired/stimulus'

let counter = 0
const uniqueId = () => {
  counter += 1
  return `${new Date().getTime().toString()}-${counter}`
}

// Connects to data-controller="utils--form--dynamic-fields"
export default class extends Controller {
  static targets = ['template']
  static values = {
    uniqueId: String
  }

  add(event) {
    event.preventDefault()

    const htmlContent = this.templateTarget.innerHTML.replace(
      new RegExp(`__CHILD_INDEX__${this.uniqueIdValue}`, 'g'),
      new Date().getTime().toString()
    ).replace(new RegExp('__UNIQUE_ID__', 'g'), uniqueId())
    event.currentTarget.insertAdjacentHTML('beforebegin', htmlContent)
  }
}
