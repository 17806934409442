import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

import { lazyI18n } from '../../utils/lazyI18n'
import { getDrawings } from '../../services/drawingsService'

// Connects to data-controller="site-task-measurements--index-single"
export default class extends Controller {
  static targets = ['draw', 'measurements', 'drawingFields', 'selectDrawing', 'measurementsFields', 'measurementTotal', 'isDrawing']
  static values = { siteId: String }

  connect() {
    this.selectDrawingSetup()
    this.element.addEventListener('updateDrawing', this.updateDrawing.bind(this))

    if (this.isDrawingTarget.value === 'true') {
      this.setDrawingMode()
    } else {
      this.setMeasurementsMode()
    }
  }

  disconnect() {
    this.selectDrawing.destroy();
    this.element.removeEventListener('updateDrawing', this.updateDrawing.bind(this))
  }

  setDrawingMode() {
    this.isDrawingTarget.value = 'true'
    this.setActive(this.drawTarget)
    this.setInactive(this.measurementsTarget)
    this.drawingFieldsTargets.forEach(t => t.classList.remove('d-none'))
    this.measurementsFieldsTargets.forEach(t => t.classList.add('d-none'))
  }

  setMeasurementsMode() {
    this.isDrawingTarget.value = 'false'
    this.setInactive(this.drawTarget)
    this.setActive(this.measurementsTarget)
    this.drawingFieldsTargets.forEach(t => t.classList.add('d-none'))
    this.measurementsFieldsTargets.forEach(t => t.classList.remove('d-none'))
  }

  selectDrawingSetup() {
    this.selectDrawing = new TomSelect(this.selectDrawingTarget, {
      valueField: 'id',
      labelField: 'title',
      searchField: 'title',
      plugins: [],
      preload: true,
      create: false,
      load: async (search, callback) => {
        const data = await getDrawings(this.siteIdValue, search)
        callback([{ id: '', title: lazyI18n('Disegno...'), total: 0 }, ...data.drawings])
      },
      maxItems: 1,
      onChange: (value) => {
        const selectedOption = this.selectDrawing.options[value]
        if (selectedOption) {
          this.measurementTotalTarget.value = selectedOption.total
        }
        window.dispatchEvent(new CustomEvent('measurementTotalChange'))
        this.cleanInvalidInputs()
      },
      render: {
        item: (drawing, escape) => `<div>${escape(drawing.title)}</div>`,
        option: (drawing, escape) => {
          return `<div data-total="${escape(drawing.total)}">${escape(drawing.title)}</div>`
        },
      },
    })
  }

  updateDrawing(event) {
    const { drawingId, drawingTitle, total } = event.detail
    if (this.selectDrawing.options[drawingId]) {
      this.selectDrawing.removeOption(drawingId)
    }

    this.selectDrawing.addOption({
      id: drawingId,
      title: drawingTitle,
      total: total
    })
    this.selectDrawing.setValue(drawingId)
  }

  setActive(target) {
    target.classList.remove('btn-outline-secondary')
    target.classList.add('btn-primary', 'active')
  }

  setInactive(target) {
    target.classList.remove('btn-primary', 'active')
    target.classList.add('btn-outline-secondary')
  }

  openDrawingModal(event) {
    event.preventDefault()
    const link = event.target.closest('a') || event.target
    const drawingId = this.selectDrawingTarget.value
    const url = drawingId ? `${link.href}&drawing_id=${drawingId}` : link.href
    fetch(url, {
      headers: { Accept: 'text/vnd.turbo-stream.html; charset=utf-8' }
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  cleanInvalidInputs() {
    document.querySelectorAll('.is-invalid').forEach(i => i.classList.remove('is-invalid'))
    document.querySelectorAll('.invalid-feedback').forEach(i => i.remove())
  }
}
