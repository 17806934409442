import i18next from 'i18next'
import { Controller } from '@hotwired/stimulus'
import { rounded } from '../../utils/numericUtils'

const readRawMeasurementRows = (measurementFieldTargets) => measurementFieldTargets.reduce((acc, current, index) => {
  const groupIndex = Math.floor(index / 6) // 6 as [subtract, same_parts, width, length, height_weight, total]
  if (!acc[groupIndex]) { acc[groupIndex] = [] }

  const value = (() => {
    switch (index % 6) {
      case 0: // subtract
        return current.value === 'true' ? -1 : 1
      case 1: // same_parts
        return parseFloat(current.value) || 1
      case 2: // width
      case 3: // length
      case 4: // height_weight
      case 5: // total
        return parseFloat(current.value)
    }
  })()

  acc[groupIndex].push(value)
  return acc
}, []).filter(el => el)

// Connects to data-controller="site-task-measurements--form"
export default class extends Controller {
  static values = {
    tab: String,
  }
  static targets = [
    'rateBtn',
    'measurementField',
    'totalQuantity',
    'quantityRounding',
    'wasteAllowance',
    'rateUnit',
    'rateUnitDisplay',
    'warningMessage',
  ]

  connect() {
    this.refreshUI()
    window.addEventListener('measurementTotalChange', this.refreshUI.bind(this))
  }

  disconnect() {
    window.removeEventListener('measurementTotalChange', this.refreshUI.bind(this))
  }

  activeMeasurementsRawValues() {
    return readRawMeasurementRows(
      Array.from(
        this.element.querySelectorAll(
          '[data-js-element="singleSiteTaskMeasurement"]:not(.d-none) ' +
            '[data-site-task-measurements--form-target="measurementField"]'
        )
      )
    )
  }

  refreshUI() {
    let rateUnits = []
    const measurementRowsValues = this.activeMeasurementsRawValues().map(row => {
      const subtractValue = row.shift()
      const total = row.pop()
      const [same_parts, ...allVals] = row

      if (!isNaN(total)) {
        return same_parts * total * subtractValue
      }

      const vals = allVals.filter(v => v > 0)

      if (vals.length === 0) {
        return 0
      }

      const res = same_parts * vals.reduce((a, v) => a * v) * subtractValue

      if (vals.length === 1) {
        rateUnits.push('ml')
        return res / 100
      }

      rateUnits.push('mq')
      return res / 10000
    })

    if (rateUnits.every((val, i, arr) => val === arr[0])) {
      const unit = rateUnits[0] || 'mq'
      this.rateUnitDisplayTarget.innerHTML = unit
      this.rateUnitTarget.value = unit
      this.warningMessageTarget.innerHTML = '&nbsp;'
    } else {
      this.rateUnitDisplayTarget.innerHTML = '-'
      if (rateUnits.length > 0) {
        this.warningMessageTarget.innerHTML = i18next.t('views.manual_measurements.qty_mismatch')
      }
    }
    this.totalQuantity = measurementRowsValues.reduce((acc, val) => acc + val, 0)

    if (this.totalQuantity > 0 && this.totalQuantity < 0.01) {
      this.warningMessageTarget.innerHTML = i18next.t('views.manual_measurements.values_too_low')
      this.totalQuantityTarget.value = 0
    }

    let finalValue = this.totalQuantity

    if (this.wasteAllowanceTarget.value != 0) {
      finalValue += finalValue * this.wasteAllowanceTarget.value
    }

    switch (this.quantityRoundingTarget.value) {
      case 'up':
        finalValue = Math.ceil(finalValue)
        break
      case 'down':
        finalValue = Math.floor(finalValue)
        break
      case 'nearest':
        finalValue = Math.round(finalValue)
        break
    }
    this.totalQuantityTarget.value = rounded(finalValue)
  }
}
