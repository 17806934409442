import { Controller } from '@hotwired/stimulus'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'

// Connects to data-controller="components--photogallery"
export default class extends Controller {
  connect() {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '.cmp-media-wrapper',
      children: '.cmp-media-image',
      pswpModule: PhotoSwipe,
      showHideAnimationType: 'fade',
    })
    lightbox.init()
  }
}
