import Constants from './constants'

export class GridDrawer {
  constructor(canvas) {
    this.canvas = canvas
  }

  drawGrid() {
    this.clearExistingGrid()
    const gridConfig = this.calculateGridConfig()
    const { verticalLines, horizontalLines } = this.calculateGridLines(gridConfig)
    this.drawLines(verticalLines, gridConfig)
    this.drawLines(horizontalLines, gridConfig)
  }

  clearExistingGrid() {
    const gridObjects = this.canvas.getObjects().filter(obj => obj.type === 'grid')
    gridObjects.forEach(obj => this.canvas.remove(obj))
  }

  calculateGridConfig() {
    const zoom = this.canvas.getZoom()
    const vpt = this.canvas.viewportTransform
    return {
      zoom,
      xOffset: vpt[4] / zoom,
      yOffset: vpt[5] / zoom,
      xModOffset: (vpt[4] / zoom % Constants.gridPixelSize) - vpt[4] / zoom,
      yModOffset: (vpt[5] / zoom % Constants.gridPixelSize) - vpt[5] / zoom,
      width: this.canvas.width / zoom,
      height: this.canvas.height / zoom,
      strokeWidth: 0.3 / zoom
    }
  }

  calculateGridLines(config) {
    const numVertical = Math.ceil(this.canvas.width / Constants.gridPixelSize / config.zoom)
    const numHorizontal = Math.ceil(this.canvas.height / Constants.gridPixelSize / config.zoom)
    const verticalLines = Array.from({ length: numVertical }, (_, i) => ({
      coords: [
        i * Constants.gridPixelSize + config.xModOffset, -config.yOffset,
        i * Constants.gridPixelSize + config.xModOffset, config.height - config.yOffset
      ],
      isVertical: true
    }))
    const horizontalLines = Array.from({ length: numHorizontal }, (_, i) => ({
      coords: [
        -config.xOffset, i * Constants.gridPixelSize + config.yModOffset,
        config.width - config.xOffset, i * Constants.gridPixelSize + config.yModOffset
      ],
      isVertical: false
    }))
    return { verticalLines, horizontalLines }
  }

  drawLines(lines, config) {
    lines.forEach(lineData => {
      const line = new fabric.Line(lineData.coords, {
        ...Constants.gridLineStyle,
        strokeWidth: config.strokeWidth
      })
      this.canvas.add(line)
      line.sendToBack()
    })
  }
}
